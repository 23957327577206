import "./styles.css";
import { useState } from "react";
import PRESTACAO from "../../assets/PRESTACAO_DE_SERVICOS_DE_VALOR_ADICIONADO.pdf";
import EQUIPAMENTOS from "../../assets/CONTRATO_DE_COMODATA_DE_EQUIPAMENTOS.pdf";
import TELECOMUNICACOES from "../../assets/CONTRATO_PRESTACAO_DE_SERVICOS_DE_TELECOMUNICACOES.pdf";
import Local2 from "../../assets/Local2.png";
import Fone2 from "../../assets/Fone2.png";
import Email2 from "../../assets/Email2.png";
import Whats3 from "../../assets/Whats3.png";
import Face from "../../assets/Face2.png";
import Insta from "../../assets/Insta2.png";
import Contato from "../Contato";

export default function Nav() {
  const [toggleState, setToggleState] = useState(1);

  const toggleNav = (index) => {
    setToggleState(index);
  };

  return (
    <div className="container-nav">
      <div className="bloc-nav">
        <button
          className={toggleState === 1 ? "navs active-navs" : "navs"}
          onClick={() => toggleNav(1)}
        >
          FALE CONOSCO
          {/* Estrutura e Planos */}
        </button>
        <button
          className={toggleState === 2 ? "navs active-navs" : "navs"}
          onClick={() => toggleNav(2)}
        >
          NOSSOS CONTATOS
        </button>
        <button
          className={
            toggleState === 3
              ? "navs navs-mobile active-navs"
              : "navs navs-mobile"
          }
          onClick={() => toggleNav(3)}
        >
          NOSSOS CONTRATOS
        </button>
      </div>
      <div className="content-tabs">
        <div
          className={toggleState === 1 ? "content  active-content" : "content"}
        >
          <Contato />
          {/* <p>
            Com uma rede moderna de rádios e fibra óptica oferecemos
            ultravelocidade e estabilidade nas conexões.
          </p>
          <p>
            Temos planos de conexão de 10 a 200 Mega, com estabilidade e sem
            franquia!
          </p> */}
        </div>

        <div
          className={toggleState === 2 ? "content  active-content" : "content"}
        >
          <div className="text">
            <div className="content-img">
              <img src={Local2} alt="Local" />
            </div>

            <p>
              <span>Endereço:</span> Rua Oliveira Brito, 382, Centro, Euclides
              da Cunha-BA - CEP: 48500-000
            </p>
          </div>
          <div className="text">
            <div className="content-img">
              <img src={Whats3} alt="Whats" />
            </div>
            <p>
              <span>Whatsapp:</span> 75 99952-6444
            </p>
          </div>
          <div className="text">
            <div className="content-img">
              <img src={Fone2} alt="Fone" />
            </div>

            <p>
              <span>Tel:</span> 75 3271-2561 | 0800 037 9090
            </p>
          </div>
          <div className="text">
            <div className="content-img">
              <img src={Email2} alt="Email" />
            </div>

            <p>
              <span>Email:</span> atendimento@allconnect.com.br
            </p>
          </div>
          <div className="social">
            <a href="https://www.instagram.com/allconnectoficiall/">
              <img src={Insta} alt="Insta" />
            </a>
            <a href="https://www.facebook.com/allconnectbandalarga">
              <img src={Face} alt="Face" />
            </a>
          </div>

          {/* <p>
            Contamos com uma equipe técnica composta por profissionais
            capacitados com certificações exigidas (NR10 e NR35), além de
            participarem de treinamentos periódicos.
          </p>
          <p>
            Disponibilizamos aos nossos clientes uma central de atendimento
            telefônico e presencial funcionando de segunda a sábado, inclusive
            nos feriados.
          </p> */}
        </div>

        <div
          className={
            toggleState === 3 ? "content-navs4  active-content" : "content"
          }
        >
          <a
            className="navs4"
            href={PRESTACAO}
            download="PRESTACAO_DE_SERVICOS_DE_VALOR_ADICIONADO"
          >
            PRESTAÇÃO DE SERVIÇO DE TELECOMUNICAÇÕES
          </a>
          <a
            className="navs4"
            href={EQUIPAMENTOS}
            download="CONTRATO_DE_COMODATA_DE_EQUIPAMENTOS"
          >
            COMODATA DE EQUIPAMENTOS
          </a>
          <a
            className="navs4"
            href={TELECOMUNICACOES}
            download="CONTRATO_PRESTACAO_DE_SERVICOS_DE_TELECOMUNICACOES"
          >
            PRESTAÇÃO DE SERVIÇOS DE VALOR ADICIONADO
          </a>
        </div>
      </div>
    </div>
  );
}
