import "./styles.css";
import { useState } from "react";
import Logo from "../../assets/LogoAllconnect.png";

function Header() {
  const [mobileNav, setMobileNav] = useState(false);
  return (
    <header>
      <nav className="width-limit content-header">
        <a href="/">
          <img src={Logo} alt="logo" />
        </a>
        <div className="container-menu">
          <div className="mobile-menu" onClick={() => setMobileNav(true)}>
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
          <ul className="nav-list">
            <li>
              <a href="/">HOME</a>
            </li>
            <li>
              <a href="#about">QUEM SOMOS?</a>
            </li>
            <li>
              <a href="#combos">COMBOS</a>
            </li>
            <li>
              <a href="#contato">CONTATO</a>
            </li>
          </ul>
          <div className="button">
            <a href="https://central.allconnect.hubsoft.com.br/">
              CENTRAL DO ASSINANTE
            </a>
          </div>
        </div>
      </nav>
      {mobileNav && (
        <div className="menuContainer">
          <ul className="nav-list-mobile">
            <li>
              <a href="/">HOME</a>
            </li>
            <li>
              <a href="#about" onClick={() => setMobileNav(false)}>
                QUEM SOMOS?
              </a>
            </li>
            <li>
              <a href="#combos" onClick={() => setMobileNav(false)}>
                COMBOS
              </a>
            </li>
            <li>
              <a href="#contato" onClick={() => setMobileNav(false)}>
                CONTATO
              </a>
            </li>
          </ul>
          <button onClick={() => setMobileNav(false)}>Voltar</button>
        </div>
      )}
    </header>
  );
}

export default Header;
