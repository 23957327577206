import "./styles.css";
import { register } from "swiper/element/bundle";

import { Swiper, SwiperSlide } from "swiper/react";

import Slider01 from "../../assets/Allconnect Slider (2).jpeg";
import Slider02 from "../../assets/Allconnect Slider (3).jpeg";
import Slider03 from "../../assets/Allconnect Slider (4).jpeg";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

register();

export default function Slider() {
  return (
    <Swiper
      sliderPerView={1}
      navigation={true}
      draggble={true}
      loop={true}
      pagination={{ clickable: true }}
    >
      <SwiperSlide>
        <div className="tex-slide">
          <h2>O melhor atendimento</h2>
          <h1>Profissionais capacitados para tirar quaisquer dúvidas</h1>
          <div className="button-slide">
            <a href="#contato">ENTRE EM CONTATO</a>
          </div>
        </div>
        <img src={Slider01} alt="Slider 01" className="imgSlider" />
      </SwiperSlide>
      <SwiperSlide>
        <div className="tex-slide">
          <h2>Cobertura em toda a cidade</h2>
          <h1>Atendimento em todos os bairros da cidade e nas proximidades</h1>
          <div className="button-slide">
            <a href="#combos">NOSSOS COMBOS</a>
          </div>
        </div>
        <img src={Slider02} alt="Slider 02" className="imgSlider" />
      </SwiperSlide>
      <SwiperSlide>
        <div className="tex-slide">
          <h2>100% FIBRA</h2>
          <h1>Sem contrato de fidelidade</h1>
          <div className="button-slide">
            <a href="#contato">SAIBA MAIS</a>
          </div>
        </div>
        <img src={Slider03} alt="Slider 03" className="imgSlider" />
      </SwiperSlide>
    </Swiper>
  );
}
