import "./styles.css";

import Ubook from "../../assets/ubook.png";
import Maia from "../../assets/maia.png";
import Hube from "../../assets/hube.png";
import Fluid from "../../assets/fluid.png";
import Socialcomics from "../../assets/socialcomics.png";
import Tap from "../../assets/tap.png";
import Clube from "../../assets/clube.png";
import Kaspersky from "../../assets/kaspersky.png";
import Ubooknews from "../../assets/ubooknews.png";
import Playkidis from "../../assets/playkidis.png";
import Toaqui from "../../assets/toaqui.png";
import Reforca from "../../assets/reforca.png";
import Qnutri from "../../assets/qnutri.png";
import Deezer from "../../assets/deezer.png";
import Looke from "../../assets/looke.png";
import Hubvantagens from "../../assets/hubvantagens.png";
import Curta from "../../assets/curta.png";
import Smart from "../../assets/Smart.png";
import RitualFit from "../../assets/RitualFit.png";
import KasperskyPlus from "../../assets/KasperskyPlus.png";
import Docway from "../../assets/Docway.png";
import Hotgo from "../../assets/Hotgo.png";
import Queima from "../../assets/Queima.png";
import Max from "../../assets/Max.png";
import NBA from "../../assets/NBA.png";

import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

export default function Aplicativo({ status }) {
  return (
    <div className="content-app-info">
      <h2>App {status === "standard" ? "Standard" : "Premium"}</h2>
      {status === "standard" && (
        <div className="app standard">
          <img src={Ubook} alt="" id="tooltip-ubook" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-ubook"
            place="bottom"
            content="Tenha acesso a mais de 100 mil títulos, entre audiobooks, ebooks, podcasts, séries, documentários e notícias com o Ubook."
          />
          <img src={Maia} alt="" id="tooltip-maia" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-maia"
            place="bottom"
            content="Nunca foi tão fácil ter uma assistente virtual, a Maia te auxiliará em várias atividades do seu dia-a-dia."
          />
          <img src={Hube} alt="" id="tooltip-hube" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-hube"
            place="bottom"
            content="Mais de 200 títulos das maiores revistas e notícias com o hube."
          />
          <img src={Fluid} alt="" id="tooltip-fluid" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-fluid"
            place="bottom"
            content="Práticas de meditação e yoga com Fluid."
          />
          <img src={Socialcomics} alt="" id="tooltip-socialcomics" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-socialcomics"
            place="bottom"
            content="A Social Comics é a maior plataforma de leitura em quadrinhos da América Latina. Títulos como Star Wars, Turma da Mônica e mais de 20 outros grandes sucessos estão disponíveis na plataforma."
          />
          <img src={Tap} alt="" id="tooltip-tap" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-tap"
            place="bottom"
            content="O app de idiomas mais fácil que existe. Flashcards para memorizar e aprender a pronúncia correta."
          />
          <img src={Clube} alt="" id="tooltip-clube" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-clube"
            place="bottom"
            content="No Clube Ciências, você aprende e desenvolve suas habilidades em diferentes áreas do conhecimento de forma interativa e divertida!"
          />
          <img src={Kaspersky} alt="" id="tooltip-Kaspersky-s" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-Kaspersky-s"
            place="bottom"
            content="Kaspersky Internet Security, a maior proteção e detecção de ameaças em tempo real, além de proteção completa para sua privacidade e identidade on-line. Licenças que podem ser utilizadas em até 3 dispositivos e tais licenças podem ser compartilhadas por e-mail."
          />
          <img src={Ubooknews} alt="" id="tooltip-ubooknews" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-ubooknews"
            place="bottom"
            content="Mantenha-se informado o tempo inteiro sobre o que acontece no Brasil e no mundo com a Ubook News."
          />
          <img src={Playkidis} alt="" id="tooltip-playkidis" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-playkidis"
            place="bottom"
            content="Ofereça diversão, aprendizado e muito conteúdo para o seu filho com o melhor conteúdo infantil."
          />
          <img src={Toaqui} alt="" id="tooltip-toaqui" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-toaqui"
            place="bottom"
            content="Um localizador e com apenas um clique, é possível acessar o mapa e ver onde estão as pessoas que mais importam para você."
          />
          <img src={Reforca} alt="" id="tooltip-reforca" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-reforca"
            place="bottom"
            content="Um app com cards que tiram as dúvidas das principais matérias de forma simples e divertida."
          />
          <img src={Qnutri} alt="" id="tooltip-qnutri" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-qnutri"
            place="bottom"
            content="O +Q Nutri é mais um produto incrível da Queima Diária para você!
Com ele você aprende o que comer, quando comer e como evitar determinados alimentos! Tudo isso sem dietas da moda ou restritivas!"
          />
          <img src={Deezer} alt="" id="tooltip-deezer" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-deezer"
            place="bottom"
            content="A Deezer é uma plataforma de streaming musical, que disponibiliza um catálogo musical diversificado de todo o mundo."
          />
          <img src={Looke} alt="" id="tooltip-looke" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-looke"
            place="bottom"
            content="O Looke é uma plataforma de streaming completa que oferece acesso ilimitados a filmes, séries, conteúdo infantil, conteúdos originais e exclusivos."
          />
          <img src={Curta} alt="" id="tooltip-curta" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-curta"
            place="bottom"
            content="Centenas de filmes e episódios de séries documentais, organizadas por temas de interesse"
          />
          <img src={Hubvantagens} alt="" id="tooltip-hubvantagens" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-hubvantagens"
            place="bottom"
            content="Enquanto seus clientes aproveitam descontos e cashbacks com o Clube de Vantagens, a sua empresa cria conexões reais com eles. São mais de 3 mil marcas e 25 mil estabelecimentos."
          />
        </div>
      )}
      {status === "premium" && (
        <div className="app premium">
          <img src={Smart} alt="" id="tooltip-smart" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-smart"
            place="bottom"
            content="Seu Lifelong Learning a qualquer hora e em qualquer lugar!"
          />
          <img src={RitualFit} alt="" id="tooltip-ritualfit" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-ritualfit"
            place="bottom"
            content="O aplicativo de treinamento pessoal para pessoas fitness."
          />
          <img src={KasperskyPlus} alt="" id="tooltip-kasperskyplus" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-kasperskyplus"
            place="bottom"
            content="KTS – Kaspersky Total Security oferece proteção de ameaças online e garante segurança e privacidade para tudo que for feito de forma online. Oferece licenças que podem ser utilizadas em até 5 dispositivos e podem ser compartilhadas por e-mail."
          />
          <img src={Docway} alt="" id="tooltip-docway" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-docway"
            place="bottom"
            content="A Docway é uma plataforma de telemedicina, que permite que você cuide da sua saúde com conforto e segurança, sem sair de casa."
          />
          <img src={Hotgo} alt="" id="tooltip-hotgo" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-hotgo"
            place="bottom"
            content="As melhores marcas de entretenimento adulto, em um só lugar."
          />
          <img src={Queima} alt="" id="tooltip-queima" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-queima"
            place="bottom"
            content="TRANSFORME O SEU CORPO E A SUA VIDA
A Queima Diária é uma plataforma com programas de exercícios para fazer em casa."
          />
          <img src={Max} alt="" id="tooltip-max" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-max"
            place="bottom"
            content="Assista a séries icônicas, filmes premiados, produções originais e muito mais na Max."
          />

          <img src={NBA} alt="" id="tooltip-nba" />
          <ReactTooltip
            className="app-toltip"
            anchorId="tooltip-nba"
            place="bottom"
            content="A NBA é a maior liga de basquete do mundo, onde jogam as super estrelas da atualidade. O melhor dos playoffs, replays, jogos ao vivo, seus jogadores e time favorito você acompanha por aqui."
          />
        </div>
      )}
    </div>
  );
}
