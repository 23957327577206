import "./styles.css";

import Iconemais from "../../assets/icone+.png";

import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

export default function Plano({ plano, preco }) {
  return (
    <div className="container-card-combos">
      <div className="box-preco">
        <h1> {plano}:</h1>
        <div className="box-h2">
          <span>R$</span>
          <h2>{preco}</h2>
        </div>
      </div>
      <div className="box-h3">
        <img src={Iconemais} alt="icone+" />
        <h3>{plano === "200 Mega" ? "Um" : "Dois"} App Standard</h3>
      </div>
      {plano === "700 Mega" && (
        <div className="box-h3">
          <img src={Iconemais} alt="icone+" />
          <h3>Um App Premium</h3>
        </div>
      )}
      <div className="box-h3">
        <img src={Iconemais} alt="icone+" />
        <h3>170 canais de tv</h3>
      </div>
      <a className="button-combos" href="#contato">
        Contato
      </a>
    </div>
  );
}
