import "./styles.css";

export default function Intro() {
  return (
    <div className="intro-box">
      <h1 id="boxh1">
        <span>Venha se juntar ao</span> maior provedor da região
      </h1>
      <a id="boxa" href="https://www.speedtest.net/pt/result/16551478845">
        <h2>TESTE SUA CONEXÃO</h2>
      </a>
    </div>
  );
}
