import "./styles.css";
import Video from "../../assets/Allconnect.mp4";
import Nav from "../../components/Nav";
import PRESTACAO from "../../assets/PRESTACAO_DE_SERVICOS_DE_VALOR_ADICIONADO.pdf";
import EQUIPAMENTOS from "../../assets/CONTRATO_DE_COMODATA_DE_EQUIPAMENTOS.pdf";
import TELECOMUNICACOES from "../../assets/CONTRATO_PRESTACAO_DE_SERVICOS_DE_TELECOMUNICACOES.pdf";

export default function Somos() {
  return (
    <div className="container-somos" id="about">
      <div className="left" id="video">
        <video src={Video} controls></video>
        <Nav />
      </div>
      <div className="right" id="bem-vindo">
        <div>
          <h2>SEJA BEM-VINDO</h2>
          <h1>UM POUCO SOBRE NÓS</h1>
          <p>
            Atuamos há mais de 20 anos no mercado, sendo o primeiro provedor de
            Internet banda larga na cidade de Euclides da Cunha - Bahia.{" "}
          </p>
          <p>
            Além de termos uma rede 100% fibra óptica, utilizarmos equipamentos
            de última geração, oferecendo mais velocidade e estabilidade de
            conexão ao usuário, procuramos capacitar periodicamente nossa equipe
            técnica, de acordo com as normas de segurança.
          </p>
          <p>
            Disponibilizamos aos nossos clientes uma central de atendimento
            telefônico e presencial funcionando todos os dias da semana
            (inclusive feriados).
          </p>
          <p>
            Somos referência em Internet e reconhecidos sempre como o melhor
            provedor de Internet em todas as pesquisas realizadas em nossa
            cidade!
          </p>
        </div>
        <div className="container-navs">
          <div className="navs2">NOSSOS CONTRATOS</div>
          <div className="content-navs3">
            <a
              className="navs3"
              href={PRESTACAO}
              download="PRESTACAO_DE_SERVICOS_DE_VALOR_ADICIONADO"
            >
              PRESTAÇÃO DE SERVIÇO DE TELECOMUNICAÇÕES
            </a>
            <a
              className="navs3"
              href={EQUIPAMENTOS}
              download="CONTRATO_DE_COMODATA_DE_EQUIPAMENTOS"
            >
              COMODATA DE EQUIPAMENTOS
            </a>
            <a
              className="navs3"
              href={TELECOMUNICACOES}
              download="CONTRATO_PRESTACAO_DE_SERVICOS_DE_TELECOMUNICACOES"
            >
              PRESTAÇÃO DE SERVIÇOS DE VALOR ADICIONADO
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
