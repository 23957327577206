import Plano from "../Plano";
import Aplicativo from "../Aplicativo";
import "./styles.css";

export default function NovosCombos() {
  return (
    <div className="combosContainer" id="combos">
      <div className="combosWrapper">
        <div className="content-titulo">
          <h1>COMBO</h1>
        </div>
        <div className="planosContainer">
          <Plano plano="200 Mega" preco="79,90" />
          <Plano plano="500 Mega" preco="99,90" />
          <Plano plano="700 Mega" preco="134,90" />
        </div>
      </div>

      <div className="container-app">
        <h1>Aplicativos</h1>
        <div className="content-app">
          <Aplicativo status="standard" />
          <Aplicativo status="premium" />
        </div>
      </div>
      <p>
        © Copyright Allconnect. Todos os direitos reservados. CNPJ: 10.593.255 /
        0001-69
      </p>
    </div>
  );
}
