import "./styles.css";
import React from "react";
import { useState } from "react";
import Header from "../../components/Header";
import Slider from "../../components/Slider";
import Intro from "../../components/Intro";
import Somos from "../../components/Somos";

import Whats2 from "../../assets/Whats2.png";

import NovosCombos from "../../components/NovoCombos";
import Contato from "../../components/Contato";
import Fim from "../../components/Fim";

function Main() {
  return (
    <div className="container-main">
      <Header />
      <Slider />
      <Intro />
      <Somos />
      <NovosCombos />
      {/* <Contato />
      <Fim /> */}
      <div className="whats-b">
        <a href="https://wa.me/75999526444">
          <img src={Whats2} alt="Whats" />
        </a>
      </div>
    </div>
  );
}

export default Main;
