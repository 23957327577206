import "./styles.css";

import { useState } from "react";

export default function Contato({ status }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  return (
    <div className="container-contato" id="contato">
      {/* <h1 className="title">Fale conosco</h1> */}

      <form
        className="form"
        action="https://formsubmit.co/atendimento@allconnect.com.br"
        method="POST"
      >
        <input
          className="input"
          type="text"
          placeholder="Digite seu nome"
          onChange={(e) => setName(e.target.value)}
          value={name}
          name="name"
          required
        />

        <input
          className="input"
          type="text"
          placeholder="Digite seu email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          name="email"
          required
        />

        <textarea
          className="textarea"
          placeholder="Digite sua mensagem..."
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          name="message"
          required
        />

        <input className="but-contato" type="submit" value="Enviar" />
      </form>
    </div>
  );
}
